.project-show-container {
    padding-top: 60px;
    
    padding-right: 10vw;
    padding-left: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.supplemental-show-container {
    padding-right: 10vw;
    padding-left: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
}

.project-show-title {
    padding-bottom: 20px;
    width: 80vw;
    max-width: 600px;
}

.link-and-date-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    width: 80vw;
    max-width: 600px;
    justify-content: space-between;
}



.project-show-pic {
    width: 80vw;
    max-width: 600px;
    margin: auto;
    padding-bottom: 20px;
}

.project-show-description {
    width: 80vw;
    max-width: 600px;
    padding-bottom: 20px;
}

.project-github-link {
    display: inline;
    text-indent: 2em;
}

.project-show-pics-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80vw;
    max-width: 600px;
   
}
.project-show-pics {
    width: 33%;
}

@media screen and (max-width: 960px) {
    .project-show-pic {
        width: 98.5vw;
        max-width: 100vw;
        transform: translate(-10vw, 0);
    }

    .project-show-container {
        padding-top: 30px;
    }

    .project-show-pics-container {
        width: 100vw;
        max-width: 100vw;
        
    }
    .project-show-pics {
        width: 33vw;
    }
}