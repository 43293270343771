.navbar {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  margin: 0 2.5rem;
}

.navbar-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  width: 50%;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
}

.nav-item-selected,
.nav-item-default {
  border-radius: 50px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  transition: all 0.5s ease;
}

.nav-item-selected {
  background: var(--sage);
  color: black;
}

.nav-item-default {
  background: transparent;
  color: white;
}

.nav-item-default:hover {
  background-color: var(--sage-60);
}

.fa-bars {
  color: #fff;
}

.fa-times {
  color: #fff;
  font-size: 2rem;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    height: 90vh;
    width: 100vw;
    position: absolute;
    top: 10vh;
    left: -200%;
    padding-bottom: 15%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242424;
    left: -2.5rem;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item-selected,
  .nav-item-default {
    border-radius: 0px;
    height: 70px;
  }

  .menu-icon {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
  }
}
