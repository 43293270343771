body,
html {
  overflow-x: hidden;
  /* cursor: url("https://i.ibb.co/W0nGW7G/logo-cursor-24-bit.png"), auto; */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "DM Mono", monospace;
}

:root {
  --sage: #79f7a3;
  --sage-60: #79f7a360;
}

a {
  text-decoration: none;
  color: var(--sage);
}

body {
  background: rgb(28, 28, 28);
  color: white;
  width: 100vw;
}

.home,
.services,
.products,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
}
