.footer-container {
  height: 10vh;
  width: 100vw - 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 2.5rem;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 140px;
  gap: 10px;
  color: #fff;
  
}

.social-icon-link {
  color: #fff;
}

.social-icon-link:hover {
  color: var(--sage);
}

.website-rights {
  color: #fff;
}

@media screen and (max-width: 820px) {
  .social-icons {
    width: 80px;
  }
}
