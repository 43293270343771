.project__cards {
    padding: 1.5rem;
    
  }
  
  .project__cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
    
  }
  
  .project__cards__wrapper {
    position: relative;
  }
  
  .project__cards__items {
    margin-bottom: 24px;
  }
  
  .project__cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border: 1px solid white;
  }
  
  .project__cards__item__link {
    color: white;
    background: linear-gradient(
    90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));

    overflow: hidden;
    text-decoration: none;
  }
  
  .project__cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .project__cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    
    padding: 10px 15px;
    max-width: calc((100%) - 60px);
    font-size: 18px;
    font-weight: 700;
    background: linear-gradient(
      90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    color: white;
    border-top: 1px solid white;
    border-right: 1px solid white;
    box-sizing: border-box;
  }
  
  .project__cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .project__cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .project__cards__item__info {
    padding: 15px;
    border-top: 1px solid white;
  }
  
  .project__cards__item__text {
    color: white;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: .25pt;
    font-size: 12p;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .project__cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .project__cards__item {
      margin-bottom: 2rem;
    }
  }