.contact-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  max-width: 950px;
  height: 80vh;
  margin: auto;
  padding: 50px 0vh;
  font-size: 16pt;
}

.contact-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 40vh;
}

.pro-pic {
  width: 100%;
  border-radius: 5%;
}
.hi {
  width: 150%;
  text-align: center;
}
.contact-description {
  width: 60vw;
  text-align: left;
  
}

.sticker-container {
  position: absolute;
  top: -20vh;
  left: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;

  width: 100vw;
  height: 120vh;

  overflow: hidden;
  z-index: -100;
  gap: 31px;
}

.sticker {
  height: 10vh;
  position: relative;
  mix-blend-mode: color-dodge;

  animation: fall 5s linear infinite;
}

@media screen and (max-width: 960px) {
  .contact-container {
    flex-direction: column;
    gap: 30px;
    align-items: center;
    margin: auto;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .pro-pic {
    width: 30vh;
  }

  .contact-description {
    width: 80vw;
    font-size: 14pt;
  }
  .sticker {
    height: 8vh;
  }

  .hi {
    width: 100%;
    text-align: center;
  }
}

.delay1 {
  animation-delay: -2s;
}
.delay2 {
  animation-delay: -1s;
}
.delay3 {
  animation-delay: -3s;
}
.delay4 {
  animation-delay: -4s;
}
.delay5 {
  animation-delay: -1.5s;
}

@keyframes fall {
  0% {
    opacity: 1;
    top: -10vh;
    transform: rotate(10deg);
  }
  50% {
    opacity: 0.2;
    transform: rotate(180deg);
  }
  100% {
    opacity: 0;
    top: 100vh;
    transform: rotate(360deg);
  }
}
