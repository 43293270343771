.hero-container {
  height: 80vh;
  width: 100vw;
  overflow: hidden;
}

.hero-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0%;
  height: 100%;
  width: 100%;
  font-family: "Staatliches";
  text-transform: uppercase;
  font-size: 7vw;
}

.hero-text-container > div {
  margin: -1vw 0;
}

.hero-image {
  position: absolute;
  opacity: 1;
  width: 5vw;
}

@media screen and (max-width: 960px) {
  .hero-image {
    width: 10vw;
  }
}
